/* Home.css */
.hero-section {
    background: url('./images/background.jpeg') no-repeat center center/cover;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 40px 60px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
    animation: fadeIn 1.5s ease-in-out;
}

.content:hover {
    transform: scale(1.05);
}

.content h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: #ffffff;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.8);
}

.content p {
    font-size: 1.3rem;
    color: #c0c0c0;
    margin-bottom: 10px;
}

.scroll-down {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

.scroll-down span {
    font-size: 1.2rem;
    animation: bounce 2s infinite;
}

/* Animation effects */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

/* Spacer styling */
.spacer {
    height: 50px;
    background-color: transparent;
}

/* Section styling */
.section {
    text-align: center;
    padding: 80px 20px;
    background-color: #f0f4f8;
    border-bottom: 1px solid #ccc;
}

.section h2 {
    font-size: 2.8rem;
    color: #1e3a8a;
    margin-bottom: 30px;
}

.section p {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}