.tool-section {
    padding: 40px 60px;
    background-color: #ffffff;
}

.tool-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    font-weight: bold;
    color: #1e3a8a;
}

.tool-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tool-item {
    width: calc(50% - 20px);
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}
.tool-item:hover {
    transform: translateY(-5px);
    /* 从 -10px 改为 -5px */
}

.tool-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248, 249, 250, 0.8);
    /* 调整透明度 */
    z-index: 1;
}

.tool-item>* {
    position: relative;
    z-index: 2;
}

.tool-item h3 {
    font-size: 24px;
    color: #000000;
    margin-bottom: 10px;
}

.tool-item p {
    font-size: 16px;
    color: #666666;
    margin-bottom: 20px;
}

.tool-item a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4273a3;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.tool-item a:hover {
    background-color: #4273a3;
}

/* 为每个工具项添加背景图片 */
.reactome-bg {
    background-image: url('./images/rt.png');
    background-size: cover;
    background-position: left top;
}

.jbrowse-bg {
    background-image: url('./images/jb.png');
    background-size: cover;
    background-position: left top;
}

.wormbase-bg {
    background-image: url('./images/wb.png');
    background-size: cover;
    background-position: left top;
}

.dockstore-bg {
    background-image: url('./images/dk.png');
    background-size: cover;
    background-position: left top;
}

@media (max-width: 768px) {
    .tool-item {
        width: 100%;
    }
}