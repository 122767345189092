/* Partners section 样式 */
.partners-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 60px;
    background-color: #ffffff;
    color: #000000;
}

/* 单个合作伙伴项样式 */
.partner-item {
    text-align: center;
}

/* 合作伙伴 logo 样式 */
.partner-logo {
    max-height: 60px;
    margin-bottom: 10px;
}

.oicr-logo {
    max-height: 45px;
}

/* Footer 链接的样式 */
.footer-links {
    text-align: center;
    padding: 20px 0;
    background-color: #ffffff;
}

.footer-links a {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
}

.footer-links a:hover {
    text-decoration: underline;
}

/* 响应式样式：针对小屏幕的调整 */
@media screen and (max-width: 768px) {
    .partners-section {
        flex-direction: column;
        /* 改为垂直布局 */
        gap: 20px;
        /* 减少元素之间的间距 */
        padding: 20px;
        /* 减小内边距 */
    }

    .partner-logo,
    .oicr-logo {
        max-height: 40px;
        /* 缩小 logo 的尺寸 */
    }

    .footer-links a {
        font-size: 12px;
        /* 缩小字体大小 */
    }
}