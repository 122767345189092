.team-container {
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 100px;
    /* 增加顶边距，避免被导航栏遮挡 */
}

.principal-investigator {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-top: 80px;
    /* 增加额外的顶部间距 */
    position: relative;
    width: 350px;
    /* 增加宽度以适应更大的照片 */
    margin-left: auto;
    margin-right: auto;
}

.pi-photo {
    background-color: #ffffff;
    width: 100%;
    height: 250px;
    /* 增加照片的高度 */
    border-radius: 10px;
    /* 保持方形样式 */
    margin-bottom: 15px;
    border: 5px solid #e0e0e0;
    /* 添加照片留白 */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    /* 添加阴影效果 */
}

.principal-investigator h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

.principal-investigator .role {
    font-weight: bold;
    font-size: 1.2em;
}

.principal-investigator .bio {
    font-size: 0.9em;
    color: #555;
    margin: 15px 0;
}

.team-group {
    margin-top: 30px;
}

.team-group h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.team-member {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    width: 250px;
    text-align: left;
}

.member-photo {
    background-color: #ffffff;
    width: 100%;
    height: 150px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.role {
    font-weight: bold;
    margin-top: 5px;
}

.bio {
    font-size: 0.9em;
    color: #555;
    margin: 10px 0;
}

.social-links {
    display: flex;
    justify-content: space-between;
}

.social-links a {
    text-decoration: none;
    color: #0073e6;
    font-size: 0.9em;
}

.social-links a:hover {
    text-decoration: underline;
}
.alumni-section {
    margin-top: 50px;
    text-align: center;
}

.alumni-section h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.alumni-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    list-style-type: none;
    padding: 0;
}

.alumni-item {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 15px;
    width: 250px;
    text-align: left;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.alumni-name {
    font-weight: bold;
    margin-bottom: 5px;
}

.alumni-former-role {
    font-style: italic;
    margin: 5px 0;
}

.alumni-current-position {
    color: #555;
}